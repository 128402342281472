import {shallowEqual, useSelector} from "react-redux";
import {FindEntity} from "../../../state/slices/entities";
import {selectEntityById} from "../../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const useCallCenterTransaction = ({entityID}) => {
  const useCoreEntitySlice = useCoreEntityContext();

  return useSelector((state) => {
    const entity = useCoreEntitySlice ? selectEntityById(state, entityID) : FindEntity(state.entities?.EntityList ?? [], entityID);
    return {
      entityID: entity?.entityid,
      ...entity?.callcenter
    };
  }, shallowEqual);
};

export default useCallCenterTransaction;
