import React, { useState, useEffect, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import {
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import TransactionManagerService from "../../../services/TransactionManagerService";
import apiClient from "../../../auth/apiClient";
import { VALID_ACCESS, BACKOUT_ACTIVITY_TYPES, SOFT_ANTIPASSBACK } from "../../../constants";
import Loading from "../..//Loading";
import SquareChip from "../../SquareChip";
import {selectContextEntity} from "../../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const trxService = new TransactionManagerService(apiClient);
const dateFormat = "MM/DD/YYYY hh:mm:ss a";

export default function CredentialHistoryButton({ className, accessHolderID }) {
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      {small ? (
        <Tooltip title="View History">
          <IconButton
            data-testid="icon-button"
            className={clsx("view-history-btn", className, classes.historyBtn)}
            color="primary"
            variant="contained"
            onClick={toggleModal}
          >
            <FontAwesomeIcon icon={faHistory} />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          className={clsx("view-history-btn", className, classes.historyBtn)}
          color="primary"
          variant="contained"
          onClick={toggleModal}
        >
          View History
        </Button>
      )}
      <Dialog
        className={classes.historyDialog}
        maxWidth="md"
        fullWidth
        open={modalOpen}
        onClose={toggleModal}
      >
        <DialogContent>
          <CredentialHistoryModal accessHolderID={accessHolderID} />
        </DialogContent>
      </Dialog>
    </>
  );
}

CredentialHistoryButton.propTypes = {
  className: PropTypes.string,
  accessHolderID: PropTypes.string,
};

const CredentialHistoryModal = ({ accessHolderID }) => {
  const classes = useStyles();
  const [history, setHistory] = useState();
  const [loading, setLoading] = useState(true);
  const useCoreEntitySlice = useCoreEntityContext();

  const timeZone = useSelector((state) =>
          (useCoreEntitySlice ? selectContextEntity(state)?.details?.timezone:  state.entities.Context?.details?.timezone) || moment.tz.guess(),
      shallowEqual
  )
  useEffect(() => {
    getAccessHolderHistory(accessHolderID);
  }, [accessHolderID]);

  const retrieveHighlightColor = (type, description) => {
    if (
      BACKOUT_ACTIVITY_TYPES.indexOf(type) != -1 ||
      (description !== null &&
        description?.toLowerCase() !== "unset" &&
        description !== VALID_ACCESS)
    ) {
      return description?.toLowerCase() === SOFT_ANTIPASSBACK.toLowerCase()
        ? "warningHighlight"
        : "errorHighlight";
    }
    return "";
  };

  const getAccessHolderHistory = useCallback(
    async (accessHolderID) => {
      setLoading(true);
      try {
        const response = await trxService.getAccessHolderHistory(
          accessHolderID,
          10
        );
        setHistory(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [accessHolderID]
  );

  const getStatus = (activityType, resultDescription) => {
    const hasResult =
      resultDescription != null &&
      resultDescription?.toLowerCase() != "unset" &&
      resultDescription.toLowerCase() != "valid access";
    return activityType + (hasResult ? " - " + resultDescription : "");
  };

  return loading === true ? (
    <Loading />
  ) : (
    <Table>
      <TableHead className="column-names">
        <TableRow>
          <TableCell data-column="access-group">Access Group</TableCell>
          <TableCell data-column="credential">Credential</TableCell>
          <TableCell data-column="device">Device</TableCell>
          <TableCell data-column="status">Result/Status</TableCell>
          <TableCell data-column="date">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="history-values">
        {history?.map((item, index) => {
          const status = getStatus(item.status, item.resultDescription);
          return (
            <TableRow key={index} className={clsx(`history-row${index}`)}>
              <TableCell data-value={clsx(item.accessGroup?.name)}>{item.accessGroup?.name}</TableCell>
              <TableCell data-value={clsx(item.credential?.reference)} className={classes.credentialCell}>
                <div className={classes.credentialContainer}>
                  <SquareChip
                    text={item.credential?.type}
                    uppercase
                    mode="info"
                  />
                  <Typography>{item.credential?.reference}</Typography>
                </div>
              </TableCell>
              <TableCell data-value={clsx(item.device?.name)}>{item.device?.name}</TableCell>
              <TableCell
                className={clsx([
                  classes.resultStatus,
                  "status",
                  retrieveHighlightColor(item.status, item.resultDescription)
                ])}
                data-value={status
                  .toLowerCase()
                  .split(" ")
                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")}
              >
                {status
                  .toLowerCase()
                  .split(" ")
                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")}
              </TableCell>
              <TableCell data-value={clsx(moment.utc(item.activityDate).tz(timeZone).format(dateFormat))}>
                {moment.utc(item.activityDate).tz(timeZone).format(dateFormat)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
