import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import apiClient from "../../../auth/apiClient";
import SearchService from "../../../services/SearchService";
import { FindNearestFacilityFromEntity } from "../../../state/slices/entities";
import SearchResults from "./SearchResults.index";
import { useStyles } from "./Search.style";
import clsx from "clsx";
import {selectNearestFacilityFromEntity} from "../../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const searchService = new SearchService(apiClient);

const SearchCategory = {
  Contract: 1,
  LicensePlate: 2,
  Name: 3,
  Ticket: 4,
  Prepaid: 5
}

export default function Search({ callID, entityID }) {

  const searchSchema = Yup.object().shape({
    searchTerm: Yup.string().required().min(3),
  });
  const useCoreEntitySlice = useCoreEntityContext();

  const defaultValues = {
    searchTerm: "",
    searchCategory: SearchCategory.Name
  };

  const classes = useStyles();

  const formProvider = useForm({
    resolver: yupResolver(searchSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, isValid }
   } = formProvider;

  const [localCallID, setLocalCallID] = useState(null);
  const [searchTerm, setSearchTerm] = useState(defaultValues.searchTerm);
  const [initialSearchFired, setInitialSearchFired] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  
  function getPropName(obj, value) {
    for (const key in obj) {
      if (obj[key] === value) {
        return key;
      }
    }
    return "Unknown";
  }

  const facility =
    useSelector((state) => (useCoreEntitySlice ? selectNearestFacilityFromEntity(entityID)(state) : FindNearestFacilityFromEntity(state.entities?.EntityList ?? [], entityID))) ?? {};

  const executeSearch = async (values) => {
    if (!isValid) {
      return;
    }

    setSearchResults(null);
    const response = await searchService.searchV3(facility.entityid, values.searchTerm, values.searchCategory);

    if (response?.status === 200) {
      const hasChildren = response.data.categories.some(category => category.children?.length > 0);
      if (hasChildren) {
        setSearchTerm(searchTerm);
        setSearchResults(response.data);
      }
    }
    setInitialSearchFired(true);
  }

  useEffect(() => {
    // the search drawer is not unmounted, reset default values if swapped to a new call
    if (localCallID !== callID) {
      setInitialSearchFired(false);
      setSearchResults([]);
      reset();
      setLocalCallID(callID);
    }
  }, [localCallID, callID])

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h3" color="primary">Search</Typography>
        <FormProvider {...formProvider}>
          <form onSubmit={handleSubmit(executeSearch)}>
            <div className={classes.searchContainer}>
              <Controller
                name="searchTerm"
                control={control}
                render={({ field }) => (
                  <TextField {...field}
                    label="Search for..."
                    id="callcenter-search-term"
                    variant="outlined"
                    className={clsx("search-field", classes.searchTerm)}
                    helperText="minimum 3 characters"/>
                )}
              />
              <FormControl variant="outlined">
                <InputLabel id="callcenter-search-category-label">Category</InputLabel>
                <Controller
                  name="searchCategory"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}
                      labelId="callcenter-search-category-label"
                      id="callcenter-search-category"
                      label="Category"
                      classes={{ select: classes.select }}
                      inputProps={{
                        "data-id": "search-category-select",
                        "data-name": getPropName(SearchCategory, field.value)
                      }}>
                      <MenuItem value={SearchCategory.Contract} data-name={getPropName(SearchCategory, SearchCategory.Contract)}>Contract</MenuItem>
                      <MenuItem value={SearchCategory.LicensePlate} data-name={getPropName(SearchCategory, SearchCategory.LicensePlate)}>License Plate</MenuItem>
                      <MenuItem value={SearchCategory.Name} data-name={getPropName(SearchCategory, SearchCategory.Name)}>Name</MenuItem>
                      {/* 
                        https://amanomcgann.atlassian.net/browse/AO-11870
                        TODO: to be reintroduced at a later date. Today, all pre-paid results
                        are covered by other categories.
                        <MenuItem value={SearchCategory.Prepaid} data-name={getPropName(SearchCategory, SearchCategory.Prepaid)}>Pre-paid</MenuItem> 
                      */}
                      <MenuItem value={SearchCategory.Ticket} data-name={getPropName(SearchCategory, SearchCategory.Ticket)}>Ticket</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            <div className={classes.resultsHeader}>
              <Typography variant="h5" color="primary" className={classes.resultsHeaderText}>
                {initialSearchFired ? "Results" : ""}
              </Typography>
              <Button
              type="submit"
              className={clsx("search-btn-submit", classes.submitBtn)}
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid}>
                { isSubmitting ? "Searching" : "Search"}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      
      <div className={classes.results}>
        {_.isEmpty(searchResults) 
          ? isSubmitting 
            ? <LinearProgress color="primary" className={classes.searchIcon}/>
            : initialSearchFired 
              ? "No Results"
              : ""
          : <SearchResults 
            callID={callID}
            entityID={entityID}
            searchResults={searchResults}
            searchTerm={searchTerm} /> }
      </div>
    </div>
  );
}