import React, { useEffect, useCallback, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
  Grid,
  Box
} from "@material-ui/core";
import { CATEGORIES } from "./Search";
import { useStyles } from "./SearchDetail.style";
import SingleSelectDropdown from "../../Dropdowns/SingleSelect";
import ContractService from "../../../services/ContractService";
import apiClient from "../../../auth/apiClient";
import { useSelector } from "react-redux";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import CredentialService from "../../../services/CredentialService";
import SquareChip from "../../SquareChip";
import SendCredentialButton from "./Buttons/SendCredentialButton";
import _ from "lodash";
import CredentialStatusChange from "../../Credentials/CredentialStatusChange";
import clsx from "clsx";
import SingleTicket from "../../TicketComponents/SingleTicket";
import TicketService from "../../../services/TicketService";
import ColoredLine from "../../ColoredLine";
import useHasPermissions from "../../../hooks/useHasPermissions";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const contractService = new ContractService(apiClient);
const credentialService = new CredentialService(apiClient);
const ticketService = new TicketService(apiClient);

export default function SearchDetail({ callID, type, data, deviceID }) {
  switch (type) {
    case CATEGORIES.AccessHolders:
      return (
        <AccessHolderDetail
          {...data}
          currentDeviceID={deviceID}
          callID={callID}
        />
      );
    case CATEGORIES.ContractCredentials:
      return (
        <AccessHolderDetail
          {...data}
          defaultCredential={data.credentialid}
          currentDeviceID={deviceID}
          callID={callID}
        />
      );
    case CATEGORIES.Tickets:
      return (
        <TicketDetail {...data} currentDeviceID={deviceID} callID={callID} />
      );
    default:
      return <></>;
  }
}

const getAllowedActions = (type, details) => {
  switch (type) {
    case CATEGORIES.AccessHolders:
      return (
        <Grid container>
          <Grid item>
            <SendCredentialButton
              callID={details.callID}
              credential={details.credential}
              deviceID={details.deviceID}
              variant="contained"
              color="primary"
            />
          </Grid>
        </Grid>
      );
    case CATEGORIES.ContractCredentials:
      return (
        <Grid container>
          <Grid item>
            <SendCredentialButton
              callID={details.callID}
              credential={details.credential}
              deviceID={details.deviceID}
              variant="contained"
              color="primary"
            />
          </Grid>
        </Grid>
      );
    case CATEGORIES.Tickets:
      return (
        <Grid container>
          <Grid item>
            <SendCredentialButton
              callID={details.callID}
              credential={details.credential}
              deviceID={details.deviceID}
              variant="contained"
              color="primary"
            />
          </Grid>
        </Grid>
      );
  }
};

const TicketDetail = ({ callID, credentialreference, currentDeviceID }) => {
  const classes = useStyles();
  const [ticketData, setTicketData] = useState();
  useEffect(() => {
    retrieveTicket(credentialreference);
  }, [credentialreference]);

  const retrieveTicket = useCallback(
    async ticketID => {
      try {
        const response = await ticketService.retrieveTicketById(ticketID);
        response.data.credentialType = "ticket";
        response.data.credentialReference = ticketID;
        setTicketData(response.data);
      } catch (err) {
        console.error(err);
      }
    },
    [credentialreference]
  );

  return ticketData ? (
    <>
      <Card>
        <CardContent>
          {getAllowedActions(CATEGORIES.Tickets, {
            credential: ticketData,
            deviceID: currentDeviceID,
            callID
          })}
          <ColoredLine />
          <SingleTicket
            hideNavigation
            fullWidth
            className={classes.ticketDetail}
            currentTicket={ticketData}
          />
        </CardContent>
      </Card>
    </>
  ) : (
    <></>
  );
};

const AccessHolderDetail = ({
  accessholderid,
  currentDeviceID,
  defaultCredential,
  callID
}) => {
  const classes = useStyles();
  const useCoreEntitySlice = useCoreEntityContext();

  const facilityID = useSelector(state => useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities?.ContextID);
  const [loading, setLoading] = useState(false);
  const [accessHolderDetails, setAccessHolderDetails] = useState();
  const [selectedCredential, setSelectedCredential] = useState();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const { hasPermissions } = useHasPermissions();
  const EditAccessHolderIO = hasPermissions(["callcenter.editAccessHolderIO"]);

  useEffect(() => {
    fetchAccessHolderDetails(accessholderid);
  }, [accessholderid, facilityID]);

  const fetchAccessHolderDetails = useCallback(
    async accessHolderID => {
      if (!accessHolderID) return;
      setLoading(true);
      try {
        const accessHolderResponse = await contractService.getAccessHolder(
          facilityID,
          accessHolderID
        );

        let accessHolder = accessHolderResponse.data;

        const credentialResponse = await credentialService.GetAccessCredentialsForAccessHolder(
          accessHolderID,
          accessHolder.contractID
        );
        accessHolder.credentials = credentialResponse.data.collection?.map(
          credential => {
            return {
              name: (
                <div className={classes.spaceBetween}>
                  <Typography className={classes.credReferenceText}>
                    {credential.credentialReference}
                  </Typography>
                  <SquareChip
                    className={classes.credentialTypeChip}
                    mode="info"
                    text={credential.credentialType}
                  />
                </div>
              ),
              value: credential.credentialID,
              ...credential
            };
          }
        );
        setSelectedCredential(
          getFormattedCredential(accessHolder.credentials, defaultCredential)
        );
        setAccessHolderDetails(accessHolder);
      } catch {
        enqueueSnackbar("Failed to retrieve access holder info", {
          variant: "error",
          tag: "FailedToRetrieveAccessholderInfo"
        });
      } finally {
        setLoading(false);
      }
    },
    [accessholderid, facilityID]
  );

  const getFormattedCredential = (credentials, credentialID) => {
    const credential = _.find(credentials, {
      credentialID
    });
    let tmp = { ...credential };
    delete tmp.name; // this is the html that is rendered in the dropdown. Don't want the noise going over signalr
    return tmp;
  };

  const handleCredentialSelect = value => {
    setSelectedCredential(
      getFormattedCredential(accessHolderDetails.credentials, value)
    );
  };

  const handleAccessHolderStatusChange = async ({ status, reason }) => {
    let tmp = { ...accessHolderDetails };
    tmp.status = status;
    tmp.statusChangeReason = reason;

    try {
      await contractService.updateAccessHolderStatus(tmp.accessHolderID, {
        status: tmp.status,
        reason: tmp.statusChangeReason
      });
      setAccessHolderDetails(tmp);
      enqueueSnackbar("Successfully updated access holder", {
        variant: "success"
      });
      setIsStatusSelected(false);
    } catch {
      enqueueSnackbar("Failed to update access holder", {
        variant: "error",
        tag: "FailedToUpdateAccessholder"
      });
    }
  };

  const handleStatusSelected = () => {
    setIsStatusSelected(true);
  };

  return (
    <Card className={classes.rootCard}>
      <CardHeader
        title={
          <div
            className={clsx(
              !isStatusSelected ? classes.smallHeader : "",
              classes.spaceBetween
            )}
          >
            <Box fontSize="larger">
              {accessHolderDetails?.contactInfo?.firstName?.concat(
                " ",
                accessHolderDetails?.contactInfo?.lastName
              )}
            </Box>
            {EditAccessHolderIO && accessHolderDetails?.status ? (
              <CredentialStatusChange
                data-id="callcenter-io-status"
                className={classes.ioStatus}
                defaultSelected={accessHolderDetails?.status?.toLowerCase()}
                onSubmit={handleAccessHolderStatusChange}
                onChange={handleStatusSelected}
              />
            ) : (
              <></>
            )}
          </div>
        }
      />
      <CardContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <SingleSelectDropdown
              className={classes.credentialDropdown}
              name="Credentials"
              variant="outlined"
              options={accessHolderDetails?.credentials ?? []}
              onSelect={handleCredentialSelect}
              defaultSelected={defaultCredential}
            />
            {selectedCredential &&
              getAllowedActions(CATEGORIES.AccessHolders, {
                credential: selectedCredential,
                deviceID: currentDeviceID,
                callID
              })}
          </>
        )}
      </CardContent>
    </Card>
  );
};
