import React, { useCallback,  useEffect,  useState } from "react"
import { useSelector } from "react-redux";
import { Box, Button, Grid, LinearProgress, Typography } from "@material-ui/core";
import _ from "lodash";

import { useStyles } from "./AccessHolderDetail.style";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import CredentialStatusChange from "../../../Credentials/CredentialStatusChange";
import SendCredentialButton from "../../Modules/Buttons/SendCredentialButton";
import { CredentialSelect } from "./CredentialSelect/CredentialSelect.index";

import ContractService from "../../../../services/ContractService";
import CredentialService from "../../../../services/CredentialService";
import apiClient from "../../../../auth/apiClient";
import clsx from "clsx";
import { faCaretUp } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UnarchiveButton from "../../Modules/Buttons/UnarchiveButton";
import { useFlags } from "launchdarkly-react-client-sdk";
import CallCenterService from "../../../../services/CallCenterService";
import {useCoreEntityContext} from "../../../../hooks/useCoreEntitySlice";

const contractService = new ContractService(apiClient);
const credentialService = new CredentialService(apiClient);
const callCenterService = new CallCenterService(apiClient);

export const AccessHolderDetail = ({
  callID,
  entityID,
  accessHolderId,
  defaultCredential, // TODO
  close
}) => {
  const classes = useStyles();
  const { routinesInventoryArchive} = useFlags();
  const useCoreEntitySlice = useCoreEntityContext();

  const facilityID = useSelector(state => useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities?.ContextID);

  const [selectedCredential, setSelectedCredential] = useState();
  const [accessHolderDetails, setAccessHolderDetails] = useState();

  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const EditAccessHolderIO = hasPermissions(["callcenter.editAccessHolderIO"]);

  const fetchAccessHolderDetails = useCallback(async () => {
    if (_.isNil(accessHolderId)) {
      return;
    }
    try {
      const accessHolderResponse = await contractService.getAccessHolder(
        facilityID,
        accessHolderId
      );
      const response = accessHolderResponse.data;

      const credentialResponse = await credentialService.GetAccessCredentialsForAccessHolder(
        accessHolderId,
        response.contractID
      );
      response.credentials = credentialResponse.data.collection;

      setAccessHolderDetails(response);
    } catch {
      enqueueSnackbar("Failed to retrieve access holder info", {
        variant: "error",
        tag: "FailedToRetrieveAccessholderInfo"
      });
    }
  }, [accessHolderId, facilityID, enqueueSnackbar]);

  const handleCredentialSelect = value => {
    setSelectedCredential(value);
  };

  const handleAccessHolderStatusChange = async ({ status, reason }) => {
    let tmp = { ...accessHolderDetails };
    tmp.status = status;
    tmp.statusChangeReason = reason;

    try {
      await contractService.updateAccessHolderStatus(tmp.accessHolderID, {
        status: tmp.status,
        reason: tmp.statusChangeReason
      });
      setAccessHolderDetails(tmp);
      enqueueSnackbar("Successfully updated access holder", {
        variant: "success"
      });
    } catch {
      enqueueSnackbar("Failed to update access holder", {
        variant: "error",
        tag: "FailedToUpdateAccessholder"
      });
    }
  };

  const handleUnarchive = async () => {
    try {
      await callCenterService.unarchivePrepaidPass(
        selectedCredential.contractID,
        selectedCredential.accessHolderID,
        selectedCredential.credentialReference
      );
      fetchAccessHolderDetails();
      enqueueSnackbar(`Prepaid Pass is unarchived.`, {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "There was an issue unarchiving the Prepaid Pass. Please try again.",
        {
          variant: "error",
          tag: "ErrorUnarchivingPrepaid Pass",
        }
      );
    }
  };

  useEffect(() => {
    if (_.isNil(accessHolderDetails)) {
      fetchAccessHolderDetails();
    }
  }, [fetchAccessHolderDetails, accessHolderDetails])

  return _.isNil(accessHolderDetails)
    ? <Box className={classes.searchingContainer}>
        <LinearProgress />
      </Box>
    : (
      <Box className={classes.expandedDetailsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5" color="primary">
              {accessHolderDetails?.contactInfo?.firstName?.concat(" ",
              accessHolderDetails?.contactInfo?.lastName)}
            </Typography>
          </Grid>
          {EditAccessHolderIO && accessHolderDetails?.status && (
            <Grid item xs={6}>
              {selectedCredential && selectedCredential.transactionState === 1 ? (
                <Typography
                  data-id="archived-status"
                  variant="h6"
                  name="archived-status"
                  align="right"
                >
                  Archived &nbsp;
                </Typography>
              ) : (
                <CredentialStatusChange
                  data-id="callcenter-io-status"
                  className={classes.ioStatus}
                  defaultSelected={accessHolderDetails?.status?.toLowerCase()}
                  onSubmit={handleAccessHolderStatusChange}/>
              )}
            </Grid>
          )}
            
          <Grid item xs={12}>
            <CredentialSelect
              className={clsx(["credential-select"])}
              credentials={accessHolderDetails?.credentials ?? []}
              onSelect={handleCredentialSelect}
              defaultSelection={defaultCredential}/>
          </Grid>

          <Grid item xs={6}>
            {/** TODO: should close pass back the loaded AH? Thus a reopen does not to reload? */}
            <Button 
              startIcon={<FontAwesomeIcon icon={faCaretUp}/>}
              className={clsx([classes.collapseAH, "collapse", "access-holder-detail"])} 
              onClick={close}>
                Collapse
              </Button>
          </Grid>

          <Grid item xs={6} className={classes.sendCredentialsBtnContainer}>
          {routinesInventoryArchive && (
            selectedCredential && selectedCredential.transactionState == 1 ? (
              <UnarchiveButton
                className={classes.sendCredentialsBtn}
                callID={callID}
                credential={selectedCredential}
                onUnarchive={handleUnarchive}
                variant="contained"
                color="primary" />
            ) : (
              <SendCredentialButton
                disabled={!selectedCredential}
                className={clsx(["button", "scan-credential",classes.sendCredentialsBtn])}
                callID={callID}
                data-call-id={callID}
                credential={selectedCredential}
                deviceID={entityID}
                variant="contained"
                color="primary" />
            )
          )}
          </Grid>
        </Grid>
      </Box>
    )
};