import React from "react";
import PropTypes from "prop-types";
import {
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Moment from "react-moment";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-duotone-svg-icons";
import RibbonCard from "../../RibbonCard";
import { useSelector, shallowEqual } from "react-redux";
import { FindEntity } from "../../../state/slices/entities";
import clsx from "clsx";
import {selectEntityById} from "../../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

export default function QueueItemCard({ callInfo, onSelect }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery("(min-width:600px)");
  const useCoreEntitySlice = useCoreEntityContext();

  const deviceInfo = useSelector((state) => {
    const device = useCoreEntitySlice ? selectEntityById(state, callInfo.deviceID) : FindEntity(
      state.entities?.EntityList ?? [],
      callInfo.deviceID
    );
    let parentName;
    if (device)
      parentName = (useCoreEntitySlice ? selectEntityById(state, device?.parententityid)?.name :
      FindEntity(state.entities?.EntityList ?? [], device?.parententityid)
          ?.name) ?? "";

    return {
      parentID: device?.parententityid,
      name: device?.name,
      parentName,
    };
  }, shallowEqual);

  return (
    <RibbonCard
      key={callInfo.callID}
      data-call-id={callInfo.callID}
      data-name={callInfo.deviceName ?? deviceInfo.name}
      data-device={callInfo.deviceID}
      showribbon={callInfo.viewing?.length > 0 ?? false}
      text={
        callInfo.viewing?.length +
        ` Viewer${callInfo.viewing?.length > 1 ? "s" : ""}`
      }
      hover={callInfo.viewing?.join()}
      style={{ width: isDesktop ? 235 : "100%" }}
      classes={["animate__animated", "animate__fadeIn"]}
    >
      <CardActionArea
        data-type="view"
        onClick={() => {
          onSelect(callInfo.callID);
        }}
      >
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {callInfo.parentName ?? deviceInfo.parentName}
          </Typography>
          <Typography variant="h6" component="h2">
            {callInfo.deviceName ?? deviceInfo.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ display: "flex" }}>
        <Moment
          style={{ color: theme.palette.text.disabled }}
          date={moment.utc(callInfo.callStart)}
          format="hh:mm:ss"
          local
          durationFromNow
        />
        {/*
                        <QueueItemCounter CallID={qi.CallID} seedSeconds={0} startDateTime={qi.DateTimeAdded}/>
                    */}
        <div style={{ flex: "1 0 0" }} />
        {
          {
            1: (
              <FontAwesomeIcon
                icon={faPhone}
                className={clsx([
                  "animate__animated",
                  "animate__flash",
                  "animate__infinite",
                ])}
                alt="Connecting"
                title="Connecting"
              />
            ),
            2: (
              <FontAwesomeIcon
                icon={faPhone}
                alt="Connected"
                title="Connected"
              />
            ),
          }[callInfo.connected?.state]
        }
      </CardActions>
    </RibbonCard>
  );
}

QueueItemCard.defaultProps = {
  callInfo: {},
  onSelect: () => {},
};

QueueItemCard.propTypes = {
  callInfo: PropTypes.shape({
    callStart: PropTypes.string,
    callID: PropTypes.number.isRequired,
    deviceID: PropTypes.string.isRequired,
    viewing: PropTypes.array,
    connected: PropTypes.object,
  }),
  onSelect: PropTypes.func,
};
