import {useDispatch} from "react-redux";
import {setEntityProperty} from "../../../state/slices/entities";
import {setEntityProperty as coreSetEntityProperty} from "../../../state/slices/CoreEntity";
import useHubContext from "../../../hooks/useHubContext";
import {useEffect} from "react";
import useCancellationToken from "../../../hooks/useCancellationToken";
import CallCenterService from "../../../services/CallCenterService";
import apiClient from "../../../auth/apiClient";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const callCenterService = new CallCenterService(apiClient);

const useCallCenterTransactionManager = ({entityID}) => {
  const {portalHub, Connected: PortalHubConnected} = useHubContext();
  const dispatch = useDispatch();
  const useCoreEntitySlice = useCoreEntityContext();

  const {execute: executeTransactionQuery, cancel: cancelQuery, inProgress} = useCancellationToken({
    func: async function ({entityid, cancelToken}) {
      const response = await callCenterService.getDeviceTransaction(entityid, cancelToken);
      dispatch(
        useCoreEntitySlice?
          coreSetEntityProperty({
            entityid: entityid,
            property: "callcenter",
            value: response.data,
          })
        :
          setEntityProperty({
            entityid: entityid,
            property: "callcenter",
            value: response.data,
          })
      );
    },
    errHandleFunc: (err) => {
      console.error("Failed to get device transaction", err)
    },
  });

  useEffect(() => {
    executeTransactionQuery({entityid: entityID});

    return () => {
      cancelQuery();
    }
  }, [entityID]);

  useEffect(() => {
    if (PortalHubConnected) {
      portalHub.subscribe(`DSD_LITE_TRANSACTION`, (message) => {
        const messageData = JSON.parse(message);
        const payload = JSON.parse(messageData.Message);
        dispatch(
            useCoreEntitySlice?
                coreSetEntityProperty({
                  entityid: entityID,
                  property: "callcenter",
                  value: payload,
                })
                :
                setEntityProperty({
                  entityid: entityID,
                  property: "callcenter",
                  value: payload,
                })
        );
      });
    }
    return () => portalHub.unsubscribe(`DSD_LITE_TRANSACTION`);
  }, [PortalHubConnected, entityID]);

  const alterGroupStatus = async (entityId, add) => {
    try {
      await portalHub.invoke(add ? "AddToCallCenterGroup" : "RemoveFromCallCenterGroup", {
        entityID: entityId,
      });
    } catch (error) {
      console.error("Couldn't update Call Center Group in Hub", entityID, error);
    }
  }
  
  useEffect(() => {
    if (PortalHubConnected) {
      alterGroupStatus(entityID, true);
    }

    return () => {
      alterGroupStatus(entityID, false);
    };
  }, [PortalHubConnected, entityID]);

  return {};
};

export default useCallCenterTransactionManager;
